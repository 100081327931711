<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card color="#174282">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Plan d'accès
            </div>
          </template>
          <v-card-text>
            <v-row>
              <v-col
                md="12"
                cols="12"
              >
                <img
                  src="plan-acces.jpg"
                  width="90%"
                  max-height="400px"
                >
                <p></p>
                <h3>Adresse :</h3>
                <p>Centre de conférence Cœur Défense, au niveau -1</p>
                <p>100-110, esplanade du Général de Gaulle - 92400 Courbevoie</p>
                <h3>Accès en transport en commum :</h3>
                <p></p>
                <p><strong>MÉTRO</strong> : Ligne 1 (Château de Vincennes – La Défense Grande Arche), arrêt "La Défense (Grande Arche)".</p>
                <p><strong>RER</strong> : Ligne A (Boissy-St-Léger / Marne-La-Vallée – Poissy / Cergy), arrêt "La Défense (Grande Arche)".</p>
                <p><strong>TRAMWAY</strong> : Ligne T2 (Issy / Val de Seine), arrêt "La Défense".</p>
                <p><strong>SNCF</strong> : Lignes Paris Saint-Lazare / Saint-Nom La Bretèche ou Versailles-Rive droite / Saint Quentin en Yvelines / La Verrière, arrêt "La Défense".</p>
                <p><strong>BUS</strong> (www.ratp.fr) : de nombreuses lignes de bus en provenance de Paris et de sa banlieue desservent le site</p>
                <p>(lignes 73, 141, 114, 159, 161, 174, 178, 258, 262, 272, 275, 278, 360, 378)</p>
                <p>Sortie F "Calder Miro" puis suivre "La Défense 4" jusqu’à l’ensemble immobilier Cœur Défense.</p>
                <p></p>
                <h3><strong>Accès en voiture</strong> :</h3>
                <p></p>
                <p>Depuis le boulevard circulaire, sortir  à "Défense 4" et emprunter l’avenue André Gleizes puis tourner à gauche pour rejoindre Cœur Défense. L’accès au parking se situe au n° 12 de l’avenue André Prothin, La Défense 4.</p>
                <p></p>
                <h3><strong>Accès en taxi et vélo</strong> :</h3>
                <p></p>
                <p>10 avenue André Prothin, La Défense 4.</p>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <hr/>
    <v-row>
      <v-col cols="4" md="4" style="text-align: center">
          Organisé par <a href="https://hors-pair.com/">Hors-Pair</a>
      </v-col>
      <v-col cols="4" offset-md="4" md="4" style="text-align: center">
          Powered by <a href="https://www.kertios.com">Kertios</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Access',
  }
</script>
